import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './Home';
import Results from './Results';
import SamsAdminMenu from './SamsAdminMenu';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/results' element={<Results/>} />
        <Route path='/s' element={<SamsAdminMenu/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
