import './SamsAdminMenu.css';
import { useState, useEffect } from 'react';
import submit from './submit.png';
import { readData, deleteOne, deleteAll } from './firebase-store';
var CryptoJS = require("crypto-js");

const adminp = '81e5b1e6c30e0e92118a8eb8a005d4a8001162db217d9a505393434cd178b024';

function SamsAdminMenu() {

  const [pw, setPW] = useState('');
  const [hasPW, setHasPW] = useState(false);
  const [results, setResults] = useState([]);

  useEffect(() => {
    getResults();
    setInterval(() => {
      getResults();
    }, 3000);
  }, []);

  async function deleteAllVotes(){
    await deleteAll();
  } 
  
  function pwEntered(){
    var hashedpw = CryptoJS.SHA256(pw.toLowerCase()).toString();
    if (hashedpw === adminp){
      setHasPW(true);
    }
  }

  async function getResults(){
    var res = await readData();
    setResults(res);
  }

  async function deleteVote(id)
  {
    await deleteOne(id);
  }
  
  return (
    <div >
        <h2 className='header'>
          Sam's Admin Menu
        </h2>
      <body style={{'color': 'white'}}>
        {
          !hasPW &&
          <div style={{'position': 'relative'}}>
            <p>Password</p>
            <input className='input' type='text' value={pw} onChange={(e) => {setPW(e.target.value)}} ></input>
            <div style={{'height': '100px'}}></div>
            <img src={submit} className='submitImg' alt='submit' onClick={pwEntered} onDragStart={(e) => e.preventDefault()} />
          </div>
        }
        {
          hasPW && 
          <>
            <p>{results.length} votes</p>
            <input type='button' value='Delete All Votes' onClick={deleteAllVotes} className='button' />
            {
              results.map((item) => 
                <div className='resultsContainer' key={item.id}>
                  <p >1st fav <strong>{item.fav}</strong></p>
                  <p >2nd fav <strong>{item.fav2}</strong></p>
                  <p >3rd fav <strong>{item.fav3}</strong></p>
                  <div className='totalContainer'>
                  <input type='button' value='Delete Vote' onClick={() => deleteVote(item.id)} className='button' />
                  </div>
                </div>
              )
            }
          </>
        }
      </body>
    </div>
  );
}

export default SamsAdminMenu;
