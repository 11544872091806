import trophy1 from './trophy1.png';
import trophy2 from './trophy2.png';
import trophy3 from './trophy3.png';
import './Results.css';
import { useEffect, useState } from 'react';
import { readData } from './firebase-store';

function Results() {

  const [results, setResults] = useState([]);
  const [sortedResults, setSortedResults] = useState([]);

  useEffect(() => {
    getResults();
    setInterval(() => {
      getResults();
    }, 3000);
  }, []);

  useEffect(() => {
    

    var parsedResults = [];
    var SOUP_CONTESTANTS = 99;
    for (var i = 1; i <= SOUP_CONTESTANTS; i++){
      var soup = {
        id: i,
        firstPlaces: 0,
        secondPlaces: 0,
        thirdPlaces: 0,
        total: 0,
        place: 0
      };
      results.forEach(res => {
        if (res["fav"] == i){
          soup.firstPlaces += 1;
          soup.total += 4;
        }
        else if (res["fav2"] == i){
          soup.secondPlaces += 1;
          soup.total += 2;
        }
        else if (res["fav3"] == i){
          soup.thirdPlaces += 1;
          soup.total += 1;
        }
      });
      parsedResults.push(soup);
    }
    parsedResults = parsedResults.filter(x => x.total > 0);

    parsedResults.sort((a, b) => {
      if (a.total < b.total) return 1;
      if (a.total > b.total) return -1;
      if (a.id < b.id) return -1;
      if (a.id > b.id) return 1;
      return 0;
    });

    var currentPlace = 1;
    var previousTotal = 0;
    parsedResults.forEach(x => {
      if (x.total < previousTotal){
        currentPlace += 1;
      }
      x.place = currentPlace;
      previousTotal = x.total;
    });

    setSortedResults(parsedResults);
    
  }, [results]);

  async function getResults(){
    var res = await readData();
    setResults(res);
  }

  

  
  return (
    <div >
        <h2 className='resultsHeader'>
          Results
        </h2>
      <body>
        <p className='resultsCount'>{results.length} votes</p>
      {sortedResults.map((item, index) => 
        <div className='resultsContainer' key={item.id}>
          {item.place === 1 && <img src={trophy1} className="trophyImg" alt="1st place trophy" />} 
          {item.place === 2 && <img src={trophy2} className="trophyImg" alt="2nd place trophy" />} 
          {item.place === 3 && <img src={trophy3} className="trophyImg" alt="3rd place trophy" />} 
          {item.place > 3 && <span className='placeLabel'>{item.place}<span className='thLabel'>th</span></span>} 
          <p className='name'>Soup {item.id}</p>
          <p className='trophyCount'><span className='label'>1st place votes:</span> <strong>{item.firstPlaces}</strong></p>
          <p className='trophyCount'><span className='label'>2nd place votes:</span> <strong>{item.secondPlaces}</strong></p>
          <p className='trophyCount'><span className='label'>3rd place votes:</span> <strong>{item.thirdPlaces}</strong></p>
          <div className='totalContainer'>
            <p className='total'>Total points: {item.total}</p>
          </div>
        </div>
      )}
      </body>
    </div>
  );
}

export default Results;
