import soup from './soup4.png';
import close from './white-close.png';
import submit from './submit.png';
import soupfesttext from './soupfesttext.png';
import './Home.css';
import { useEffect, useRef, useState } from 'react';
import { handleSubmit, docExists } from './firebase-store';
import { v4 as uuidv4 } from 'uuid';
import { useLongPress } from 'use-long-press';
import { readData } from './firebase-store';
var CryptoJS = require("crypto-js");


const p = 'a5cd1d7cbdd49c27aacc041890a06f107dc63b582e5fff92a45edb1c1f6106f2'

function Home() {

  const [pw, setPW] = useState('');
  const [hasPW, setHasPW] = useState(false);
  const [pwErr, setPWErr] = useState(false);
  const [fav, setFav] = useState('');
  const [fav2, setFav2] = useState('');
  const [fav3, setFav3] = useState('');
  const [toasts, setToasts] = useState([]);

  const [localID, setLocalID] = useState('');
  const [hasVoted, setHasVoted] = useState(false);

  const [resultCount, setResultCount] = useState(1);
  const toastTimerRef = useRef();

  useEffect(() => {

    var _localID = localStorage.getItem('localID');

    if (!_localID){
      _localID = uuidv4();
      localStorage.setItem('localID', _localID);
    }
    setLocalID(_localID);

    docExists(_localID).then(x => {
      setHasVoted(x);
    })

    var _p = localStorage.getItem('p');
    if (_p === p){
      setHasPW(true);
    }
  }, []);

  useEffect(() => {
    if (hasVoted)
    {
      getResultCount();
      setInterval(() => {
        getResultCount();
      }, 3000);
    }
  }, [hasVoted]);

  async function getResultCount(){
    var res = await readData();
    setResultCount(res.length);
  }
  
  useEffect(() => {
    if (toastTimerRef.current)
    {
      clearTimeout(toastTimerRef.current);
      toastTimerRef.current = undefined;
    }
    if (toasts.length > 0)
    {
      toastTimerRef.current = setTimeout(() => {
        setToasts([]);
      }, 10000);
    }
  }, [toasts]);

  function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  function pwEntered(){
    var hashedpw = CryptoJS.SHA256(pw.toLowerCase()).toString();
    console.log(hashedpw);
    if (hashedpw === p){
      setHasPW(true);
      localStorage.setItem('p', hashedpw);
    }
    else{
      setPWErr(true);
    }
  }

  async function submitPressed(){
    var toastMessages = [];
    if (!isNumeric(fav)){
      toastMessages.push('1st favorite must be a valid number');
    }
    else if (fav < 1 || fav > 99){
      toastMessages.push('1st favorite is out of range');
    }

    if (!isNumeric(fav2)){
      toastMessages.push('2nd favorite must be a valid number');
    }
    else if (fav2 < 1 || fav2 > 99){
      toastMessages.push('2nd favorite is out of range');
    }

    if (!isNumeric(fav3)){
      toastMessages.push('3rd favorite be a valid number');
    }
    else if (fav3 < 1 || fav3 > 99){
      toastMessages.push('3rd favorite is out of range');
    }
    
    
    if (((!!fav && !!fav2) && fav == fav2) || ((!!fav && !!fav3) && fav == fav3) || ((!!fav2 && !!fav3) && fav2 == fav3)){
      toastMessages.push('Each favorite must be for a different soup');
    }

    if (toastMessages.length > 0){
      setToasts(toastMessages);
    }
    else{
      const err = await handleSubmit({name: localID, fav: parseInt(fav), fav2: parseInt(fav2), fav3: parseInt(fav3)});
      if (!err){
        setHasVoted(true);
        localStorage.setItem('hasVoted', true);
      }
      else{
        setToasts([err]);
      }
    }
  }

  const bind = useLongPress(() => {
    localStorage.setItem('hasVoted', false);
    setHasVoted(false);
  });

  function closeToast(){
    setToasts([]);
  }

  return (
    <div className="App">
      {hasVoted && <img src={soup} className="App-logo" alt="logo" />}
      {!hasVoted && <img src={soupfesttext} className="soup-fest-title" alt="soup fest 2023"></img>}
      {!hasVoted && hasPW && <span className='instructionsText'>enter the numbers of your 3 favorite soups!</span>}
        <body>
          {
            hasVoted &&
            <>
              <p{...bind()}>
              Thanks for voting!
              </p>
              <span>{resultCount} votes</span>
            </>
          }
          {
            !hasVoted && !hasPW &&
            <div style={{'position': 'relative'}}>
              <p className='label'>Password</p>
              <input className='input' type='text' value={pw} onChange={(e) => {setPWErr(false); setPW(e.target.value)}} ></input>
              <div style={{'height': '100px'}}></div>
              { pwErr && <p style={{'color': 'red', 'position': 'absolute', 'top': '30%', 'width': '100%'}}>nope</p>}
              <img src={submit} className='submitImg' alt='submit' onClick={pwEntered} onDragStart={(e) => e.preventDefault()} />
            </div>
          }
          {
            !hasVoted && !!hasPW &&
            <>
              <p className='label'>1st favorite</p>
              <input className='input' type='number' value={fav} onChange={(e) => {setFav(e.target.value)}}></input>

              <p className='label'>2nd favorite</p>
              <input className='input' type='number' value={fav2} onChange={(e) => {setFav2(e.target.value)}}></input>

              <p className='label'>3rd favorite</p>
              <input className='input' type='number' value={fav3} onChange={(e) => {setFav3(e.target.value)}}></input>

              <div style={{'height': '60px'}}></div>

              <img src={submit} className='submitImg' alt='submit' onClick={submitPressed} onDragStart={(e) => e.preventDefault()} />
            </>
          }
          <div style={{'height': '60px'}}></div>
          {
            toasts.length > 0 && 
            <div className='toast'>
              <img src={close} className='close' onClick={closeToast} click />
              {
                toasts.map(toast => 
                  <p>{toast}</p>
                )
              }
            </div>
          }
        </body>
    </div>
  );
}

export default Home;
