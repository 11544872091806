import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getFirestore } from "@firebase/firestore"
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyDsfI-3P9jGZPpqopJzH47MN7NMPogX_34",
  authDomain: "soupfest-fffc3.firebaseapp.com",
  projectId: "soupfest-fffc3",
  storageBucket: "soupfest-fffc3.appspot.com",
  messagingSenderId: "584564517141",
  appId: "1:584564517141:web:bc1aa28fa9752b3883e11c",
  measurementId: "G-DP1QWFJMS5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const firestore = getFirestore(app)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
