import { setDoc, getDocs, collection, doc, getDoc, deleteDoc } from "@firebase/firestore"
import { firestore } from "./index"



var collectionName = "test_data";

export const handleSubmit = async (data) => {
    
    
    var ref = doc(firestore, collectionName, data.name);
    
    try {
        setDoc(ref, data)
        return null;
    } catch(err) {
        return err;
    }
}

export const docExists = async (docName) => {
    var ref = doc(firestore, collectionName, docName);
    var myDoc = await getDoc(ref);
    return myDoc.exists();
}

export const readData = async () => {
    var res = await getDocs(collection(firestore, collectionName))
        .then((querySnapshot)=>{               
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
            return newData;
        });
    return res;
}

export const deleteAll = async () => {
    var allDocs = await getDocs(collection(firestore, collectionName));
    allDocs.docs.forEach(async (x) => {
        await deleteDoc(x.ref);
    })
}

export const deleteOne = async (id) => {
    var ref = doc(firestore, collectionName, id);
    await deleteDoc(ref);
}